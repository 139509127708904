import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { isIP } from "is-ip";
import { Netmask } from "netmask";

import PageError from "../../components/Error/PageError";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import PendingChannelBanner from "../../components/PendingChannels/PendingChannelBanner";
import store from "../../store";
import { SimpleActivityList } from "./SimpleActivityList/SimpleActivityList";
import { useChannel } from "../../hooks/Channel/useChannel";
import { SimpleChannelHeader } from "../../components/SimpleChannelHeader/SimpleChannelHeader";
import { useUserState } from "../../hooks/useUserState";
import { useChannelRole } from "../../hooks/Channel/useChannelRole";
import { LoginEnum } from "../../Redux/Users/UserTypes";

import style from "./SimpleChannel.module.scss";

const SimpleChannel = (props) => {
  //Props
  let { channelId } = useParams();

  // States
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [_activities, setActivities] = useState(null);
  const [storedChannelId, setStoredChannelId] = useState("");
  const [userIp, setUserIp] = useState(null);
  const [accessAllowed, setAccessAllowed] = useState(false);
  const [loginStatus, setLoginStatus] = useState("init");

  const userMeta = useUserState();
  const { role } = useChannelRole(channelId);
  let {
    channel,
    error: getChannelError,
    isFetchingChannel,
  } = useChannel(channelId);

  //Effect hook for is logged in
  useEffect(() => {
    if (loginStatus !== userMeta.loginStatus) {
      setLoginStatus(userMeta.loginStatus);
    }
  }, [userMeta.loginStatus, loginStatus]);

  // Fetch client IP using ipify API
  const fetchClientIp = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      setUserIp(response.data.ip);
    } catch (error) {
      setError("Failed to fetch client IP.");
    }
  };

  // Helper function to validate IP within CIDR range
  const checkIPInRange = (ip, rangeString) => {
    const ranges = rangeString.split(",").map((range) => range.trim());

    return ranges.some((range) => {
      if (isIP(range)) {
        // Exact IP match
        return ip === range;
      } else {
        // CIDR range match using cidr-tools
        const net = new Netmask(range);
        return net.contains(ip);
      }
    });
  };

  // Validate access based on conditions
  const validateAccess = useCallback(() => {
    if (!channel) return;

    // Public access - immediate allowance
    if (channel.simple_access === "public") {
      setAccessAllowed(true);
      return;
    }

    // If not IP-restricted, deny access
    if (channel.simple_access !== "ip_range") {
      setAccessAllowed(false);
      return;
    }

    // Check if the IP is within the allowed range and within valid time periods
    if (userIp && channel.ip_ranges) {
      const isValidRange = channel.ip_ranges.some((range) => {
        const ipInRange = checkIPInRange(userIp, range.ranges);
        const now = Date.now();
        return ipInRange && now >= range.start && now <= range.end;
      });

      if (isValidRange) {
        setAccessAllowed(true);
        return;
      }
    }

    if (loginStatus !== LoginEnum.isLoggedIn) {
      setAccessAllowed(false);
      return;
    }

    setAccessAllowed(["none", "owner", "admin", "author"].includes(role));
  }, [channel, userIp, loginStatus, role]);

  // Reset when channel changes
  useEffect(() => {
    if (channelId !== storedChannelId) {
      setStoredChannelId(channelId);
      setIsLoading(true);
      setActivities(null);
      setAccessAllowed(false); // Reset access permission
    }
  }, [channelId, storedChannelId]);

  // Fetch activities and validate access after channel data is available
  useEffect(() => {
    if (!isFetchingChannel && channel && !_activities) {
      fetchClientIp();

      let updateActivities = channel.activities.map((actId) => {
        let act = store.getState().Activities.list[actId];

        return act;
      });

      setActivities(updateActivities);
    }
  }, [channel, _activities, isFetchingChannel]);

  // Check access once the client IP is fetched
  useEffect(() => {
    if (userIp) {
      validateAccess();
    }
  }, [userIp, channel, validateAccess]);

  //handle is loading
  useEffect(() => {
    if (isLoading && !isFetchingChannel) {
      if (channel && _activities) {
        setIsLoading(false);
      }
    }
  }, [isLoading, channel, _activities, isFetchingChannel]);

  // Monitor for errors
  useEffect(() => {
    if (getChannelError) {
      setError("Something went wrong while getting channel " + getChannelError);
    }
  }, [getChannelError]);

  const renderActivitiesList = () => {
    return (
      <SimpleActivityList activities={_activities} channelId={channelId} />
    );
  };

  function renderContent() {
    if (error) {
      return <PageError error={true} errorMessage={error} />;
    } else if (!isLoading && accessAllowed && userIp) {
      return (
        <div>
          <PendingChannelBanner channelId={channelId} history={props.history} />
          <div className={style.content}>
            <SimpleChannelHeader
              channelName={channel.name}
              channelImage={channel.image_url}
            />
            {renderActivitiesList()}
          </div>
        </div>
      );
    } else if (
      (isLoading || !accessAllowed) &&
      channel &&
      channel.id === storedChannelId &&
      userIp
    ) {
      return (
        <div>
          <PendingChannelBanner channelId={channelId} history={props.history} />
          <div className={style.content}>
            <SimpleChannelHeader
              channelName={channel.name}
              channelImage={channel.image_url}
            />
            {!accessAllowed && !isLoading && (
              <div className={style.accessDenied}>
                <h3>Access Denied</h3>
                <p>You do not have permission to access this channel.</p>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return <PageLoadingIndicator />;
    }
  }

  return <>{renderContent()}</>;
};

export default SimpleChannel;
